import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import CreditLimitBadge from "@ctv/app/[locale]/(post-login)/(management)/company/info/_components/legacy/(BillingInfo)/CreditLimitBadge";
import CreditLimitIcon from "@ctv/app/[locale]/(post-login)/(management)/company/info/_components/legacy/(BillingInfo)/CreditLimitIcon";
import { useContentResource } from "@ctv/core";
import { appendTestId, Text, Token } from "@ctv/shared-core/src";
import RetryIcon from "@traveloka/icon-kit-web/react/IcSystemRetry";
import { useAccountStatus } from "../../account-status/AccountStatusContext";
import { useCreditLimit } from "../CreditLimitContext";

type Props = {
  testID?: string;
};

function MenuCreditLimit(props: Props) {
  const { testID } = props;

  const { data: creditLimit, fetchCreditLimit, isFetching } = useCreditLimit();
  const { isSuspended } = useAccountStatus();
  const content = useContentResource().CorporateCreditLimitMenu;
  const creditLimitEnum = useContentResource().CorporateEnumCreditLimit;
  const accountStatusContent = useContentResource().CorporateAccountStatus;

  if (!creditLimit) {
    return (
      <View testID={appendTestId(testID, "menu")} style={Style.container}>
        <CreditLimitIcon
          testID={appendTestId(testID, "menu")}
          style={Style.icon}
          status={undefined}
        />
        <View style={Style.retry}>
          <Text variant="ui-tiny" ink="highlight">
            {content.failToLoadText}
          </Text>

          {!isFetching && (
            <TouchableOpacity
              testID={appendTestId(testID, "menu.retry")}
              onPress={fetchCreditLimit}
            >
              <RetryIcon color={Token.color.bluePrimary} />
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  }

  if (isSuspended) {
    return (
      <View testID={appendTestId(testID, "menu")} style={Style.container}>
        <CreditLimitIcon
          testID={appendTestId(testID, "menu")}
          style={Style.icon}
          status={creditLimit.remainingCreditLimit.status}
          isSuspended
        />

        <View>
          {creditLimit.hasPermission && (
            <CreditLimitBadge
              testID={appendTestId(testID, "menu")}
              status={"EMPTY"}
              percentage={accountStatusContent.suspended}
            />
          )}
          <Text variant="ui-tiny">
            {accountStatusContent.outstandingInvoice}
          </Text>
        </View>
      </View>
    );
  }

  return (
    <View testID={appendTestId(testID, "menu")} style={Style.container}>
      <CreditLimitIcon
        testID={appendTestId(testID, "menu")}
        style={Style.icon}
        status={creditLimit.remainingCreditLimit.status}
      />

      <View>
        {creditLimit.hasPermission && (
          <CreditLimitBadge
            testID={appendTestId(testID, "menu")}
            status={creditLimit.remainingCreditLimit.status}
            percentage={creditLimit.remainingPercentage}
          />
        )}
        <Text variant="ui-tiny">
          {creditLimitEnum[creditLimit.remainingCreditLimit.status]}
        </Text>
      </View>
    </View>
  );
}

const Style = StyleSheet.create({
  container: {
    flexDirection: "row",
    paddingLeft: Token.spacing.l,
    paddingVertical: Token.spacing.s,
    alignItems: "center",
    height: 60,
    borderTopWidth: Token.borderWidth.thin,
    borderTopColor: Token.color.borderDivide,
  },
  icon: {
    marginRight: Token.spacing.ml,
  },
  retry: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
    paddingRight: Token.spacing.m,
  },
});

export default MenuCreditLimit;
