import { Fragment } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";

import {
  Badge,
  Image,
  Text,
  Token,
  useLocalizedRouterServer,
  View,
} from "@ctv/shared-core/src";

import IcSystemCheckmark from "@traveloka/icon-kit-web/react/IcSystemCheckmark";

type Props = {
  current: number;
  steps: string[];
};

export default function StepHeader(props: Props) {
  const { current, steps } = props;

  const router = useLocalizedRouterServer();
  const wrapperStyle = {
    backgroundColor: Token.color.lightPrimary,
    borderTopColor: Token.color.bluePrimary,
  };
  const separatorStyle = { backgroundColor: Token.color.lightSecondary };

  return (
    <View style={[styles.wrapper, wrapperStyle]}>
      <View style={styles.container} row justify="between" align="center">
        <TouchableOpacity onPress={() => router.push("/home")}>
          <Image
            src="/images/traveloka-for-corporates.png"
            width={279}
            height={28}
          />
        </TouchableOpacity>
        <View row align="center">
          {steps.map((step, index) => (
            <Fragment key={index}>
              {index > 0 && <View style={[styles.separator, separatorStyle]} />}
              <View style={styles.number}>
                {index < current ? (
                  <View style={styles.checkmark}>
                    <IcSystemCheckmark
                      color={Token.color.lightPrimary}
                      width={16}
                      height={16}
                    />
                  </View>
                ) : (
                  <Badge
                    variant={index === current ? "info" : "neutral-subtle"}
                    text={String(index + 1)}
                  />
                )}
              </View>
              <Text
                variant="ui-small"
                ink="black-secondary"
                style={styles.text}
              >
                {step}
              </Text>
            </Fragment>
          ))}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    boxShadow: Token.shadow.float,
    top: 0,
    zIndex: 3,
    // @ts-expect-error -- position sticky is not available in react-native
    position: "sticky",
    borderTopWidth: 4,
    borderStyle: "solid",
  },
  container: {
    width: "100%",
    maxWidth: 1640,
    paddingVertical: Token.spacing.m,
    paddingHorizontal: Token.spacing.l,
    marginHorizontal: "auto",
  },
  separator: {
    marginHorizontal: Token.spacing.s,
    height: Token.borderWidth.bold,
    width: Token.spacing.xxl,
  },
  number: {
    width: 24,
    height: 24,
    // @ts-expect-error -- userSelect none is not available in react-native
    userSelect: "none",
  },
  text: {
    marginLeft: Token.spacing.xs,
  },
  checkmark: {
    backgroundColor: Token.color.greenPrimary,
    borderRadius: Token.borderRadius.rounded,
    padding: Token.spacing.xxs,
  },
});
